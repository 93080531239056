// extracted by mini-css-extract-plugin
export var heroBackground = "research-module--heroBackground--3ZQ9A";
export var heroImage = "research-module--heroImage--32PjB";
export var heroTitleCont = "research-module--heroTitleCont--3lx6v";
export var heroImagesContainer = "research-module--heroImagesContainer--MZzPi";
export var imageCard = "research-module--imageCard--fSw6j";
export var card1 = "research-module--card1--3cQSt";
export var card2 = "research-module--card2--1bGFk";
export var card3 = "research-module--card3--2Oe6Y";
export var card4 = "research-module--card4--3RKNH";
export var card5 = "research-module--card5--3BT2j";
export var svg1 = "research-module--svg1--3S5cG";
export var titleCont = "research-module--titleCont--1dx3m";
export var researchCardContent = "research-module--researchCardContent--qjNbw";
export var svg2 = "research-module--svg2--GNyIf";
export var svg3 = "research-module--svg3--6uscE";
export var researchImage = "research-module--researchImage--1r9Bh";
export var cardMargin = "research-module--cardMargin--2e3SJ";