import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"

import {
    heroBackground,
    heroTitleCont,
    heroImagesContainer,
    imageCard,
    card1,
    card2,
    card3,
    card4,
    card5,
    svg1,
    titleCont,
    researchCardContent,
    researchImage,
    svg2, 
    svg3,
    cardMargin
} from "../styles/research.module.scss"

import image1 from "../images/Royal-Free-Hospital-3-small.jpg"
import image2 from "../images/microscope.png"
import image3 from "../images/research-hand.png"
import image4 from "../images/scientist-back.png"
import image5 from "../images/Royal-Free-Hospital-4-small.jpg"

const Research = () => (
    <Layout>
        <Seo title="Research" />
        <section>
            <div className={heroBackground}>
                <div className={heroTitleCont}>
                    <Container>
                        <Row className="align-items-center">
                            <Col sm={12} md={8} lg={6}>
                                <div>
                                    <h2>
                                        Exploring The Frontiers of Science Today to Revolutionise the Care of Patients Tomorrow
                                    </h2>
                                    <p>
                                        Our work revolves around the priorities of our patients, bringing together scientists, academic clinicians, clinical trials specialists and nurses to translate new research into novel treatments.
                                    </p>
                                </div>
                            </Col>
                            <Col sm={12} md={4} lg={6} className="d-none d-md-block">
                                <div className={heroImagesContainer}>
                                    <div className={`${imageCard + ' ' + card1} card`} style={{
                                        backgroundImage: `url(${image1})`
                                    }}></div>
                                    <div className={`${imageCard + ' ' + card2} card`} style={{
                                        backgroundImage: `url(${image2})`
                                    }}></div>
                                    <div className={`${imageCard + ' ' + card3} card`} style={{
                                        backgroundImage: `url(${image3})`
                                    }}></div>
                                    <div className={`${imageCard + ' ' + card4} card`} style={{
                                        backgroundImage: `url(${image4})`
                                    }}></div>
                                    <div className={`${imageCard + ' ' + card5} card`} style={{
                                        backgroundImage: `url(${image5})`
                                    }}></div>
                                </div>
                            </Col>
                        </Row>

                    </Container>
                </div>
            </div>
        </section>
        <section style={{ background: 'white' }}>
            <svg width="100%" viewBox="0 0 500 300" preserveAspectRatio="none" className={`${svg1} background-svg`} >
                <path d="M0,300 L0,0 Q200,280 500,250 L500, 300 Z" fill="white" />
            </svg>

            <Container>
                <Row>
                    <Col md={6}>
                        <div className={titleCont}>
                            <h1>Our Research Priorities</h1>
                            <div className="title-highlight"></div>
                        </div>
                    </Col>
                </Row>

                <Row className="align-items-center">
                    <Col xs={{span: 12, order: 2}} md={{span:7, order: 1}}>
                        <div className={`${cardMargin} card`} style={{ zIndex: 2 }}>
                            <div className={researchCardContent}>
                                <h2>3D Printing</h2>
                                <p>
                                    Investigation of viable ways of replacing missing body parts using new technology in three-dimensional (3D) printing. The technology allows creating accurate and reproducible implants to replace the tissue and organs damaged by cancer, trauma and congenital deformities. We create living biological replacements for diseased organs (including the ear, nose and facial bones) by combining stem cells with implants using bio-printing technology.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={{span: 12, order: 1}} md={{ span: 5, order: 2 }} className="d-flex justify-content-center">
                        <StaticImage
                            src="../images/3D-printing.png"
                            formats={["AUTO", "WEBP"]}
                            style={{ maxWidth: '25rem' }}
                            className={researchImage}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
        <section style={{ background: 'black' }}>
            <svg width="100%" viewBox="0 0 500 120" preserveAspectRatio="none" className={`${svg2} background-svg`} >
                <path d="M0,0 L0,40 Q250,120 500,40 L500,0 Z" fill="white" />
            </svg>
            <Container>
                <Row className="align-items-center">
                    <Col xs={{span: 12, order: 2}} md={{ span: 7, order: 2 }}>
                        <div className={`${cardMargin} card`}>
                            <div className={researchCardContent}>
                                <h2>Advanced Polymers</h2>
                                <p>
                                Development of a polymer to create a compact, lightweight dressing to be used in battlefield treatment of blast trauma and burns. The material will be easy to use at a military conflict zones and also applicable to people and public servants burned in house fires, road accidents etc.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col sm={{span: 12, order: 1}} md={{ span: 5, order: 1 }} className="d-flex justify-content-center">
                        <StaticImage
                            src="../images/polymer.png"
                            formats={["AUTO", "WEBP"]}
                            style={{ maxWidth: '20rem' }}
                            className={researchImage}
                        />
                    </Col>
                </Row>
            </Container>
            <svg width="100%" viewBox="0 0 500 120" preserveAspectRatio="none" className={`${svg3} background-svg`}  style={{background: "black"}}>
                <path d="M0,120 L0,80 Q250,0 500,80 L500,120 Z" fill="#FAFAFA" />
            </svg>
        </section>
        <section style={{marginBottom: '6rem'}}>
            <Container>
                <Row className="align-items-center">
                    <Col xs={{span: 12, order: 2}} md={{span:7, order: 1}}>
                        <div className="card">
                            <div className={researchCardContent}>
                                <h2>Stem Cell Research</h2>
                                <p>
                                    Exploring new ways of treating currently untreatable conditions using stem cell biology, with specific focus on the treatment of scleroderma and radiation fibrosis. We have already successfully used stem cells obtained from the fat of the patient’s body to treat diseased areas, causing dramatic improvements in appearance and function. We now need to investigate exactly why this treatment works and how to improve the process of obtaining stem cells.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={{span: 12, order: 1}} md={{ span: 5, order: 2 }} className="d-flex justify-content-center">
                        <StaticImage
                            src="../images/stem-cell.png"
                            formats={["AUTO", "WEBP"]}
                            style={{ maxWidth: '20rem' }}
                            className={researchImage}
                        />
                    </Col>
                </Row>
            </Container>
        </section>
    </Layout>
)

export default Research
